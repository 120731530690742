<template>
  <v-app class="notranslate" translate="no">
    <sidebar ref="sidebar" />
    <v-main>
      <!-- <v-app-bar flat color="white">
        <v-spacer></v-spacer>
        <language-selector />
      </v-app-bar> -->
      <v-container fluid>
        <ToastSnackbar
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
        />
        <router-view />
      </v-container>
    </v-main>
    <v-footer class="mt-10">
      <v-spacer></v-spacer>
      <span class="text--disabled mr-3">Forest Chain</span>
      <div>&copy; {{ new Date().getFullYear() }}</div>
    </v-footer>
  </v-app>
</template>

<script>
import Sidebar from '@/views/sidebar/SidebarShow'
// import LanguageSelector from '../components/LanguageSelector'
import ToastSnackbar from '../components/ToastSnackbar'
import { mapGetters } from 'vuex'
export default {
  components: {
    Sidebar,
    // LanguageSelector,
    ToastSnackbar
  },
  computed: {
    ...mapGetters({
      notifications: 'notification/notifications'
    })
  }
}
</script>
