<template>
  <v-navigation-drawer
    v-model="drawer"
    dark
    app
    permanent
    expand-on-hover
    width="20%"
  >
    <!-- :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown" -->
    <v-list>
      <v-list-item
        v-if="isAdmin"
        class="px-2"
        :disabled="!isAdmin || !currentUserGroup"
        :to="{
          name: 'user-group-show',
          params: {
            category: 'supplier',
            groupId: currentUserGroup && currentUserGroup.id,
            lang: locale
          }
        }"
      >
        <v-list-item-avatar>
          <v-img :src="loadedLogo" alt="Avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{
            organization.data.attributes.name || '-'
          }}</v-list-item-title>
          <v-list-item-subtitle class="green--text"
            >Hello,
            {{
              currentUser.attributes.info.name || currentUser.attributes.email
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-else class="px-2">
        <v-list-item-avatar>
          <v-img :src="loadedLogo" alt="Avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{
            organization.data.attributes.name || '-'
          }}</v-list-item-title>
          <v-list-item-subtitle class="green--text"
            >Wood for Music</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Dashboard -->
    </v-list>
    <v-list v-if="isAdmin" dense>
      <!-- List Items -->
      <v-list-item
        v-for="item in listItems"
        :key="item.title"
        :to="{ name: item.name, params: { lang: locale } }"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-for="item in listItemGroups"
        :key="item.title"
        :value="false"
        color="green lighten-4"
        :prepend-icon="item.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="subItem in item.items"
          :key="subItem.title"
          :to="{
            name: subItem.name,
            params: { category: subItem.category, lang: locale }
          }"
        >
          <v-list-item-title v-text="subItem.title"> </v-list-item-title>
          <!-- <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon> -->
          <!-- <v-list-item-action v-if="routePath === subItem.link">
            <v-btn
              :to="subItem.button"
              icon
              small
              v-show="routePath === subItem.link"
            >
              <v-icon color="green lighten-3">mdi-plus</v-icon></v-btn
            >
          </v-list-item-action> -->
        </v-list-item>
      </v-list-group>
    </v-list>

    <!-- User Links -->
    <v-list v-else dense>
      <v-list-item
        v-for="item in userItems"
        :key="item.title"
        :to="{ name: item.name, params: { lang: locale } }"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- Sidebar Footer -->
    <template v-slot:append>
      <v-list dense>
        <v-list-group
          :value="false"
          color="green lighten-4"
          no-action
          prepend-icon="mdi-google-translate"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('common.translate')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(locale, index) in locales"
            :key="index"
            dense
            @click="updateLocale(locale.value)"
          >
            <v-list-item-title>{{ locale.text }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          :to="{
            name: 'user-edit',
            params: {
              role: currentUser.attributes.roles[0],
              userId: currentUser.id
            }
          }"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{
              $t('common.editProfile')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logOut">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('auth.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      drawer: false,
      langParam: this.$route.params.lang || 'es',
      locales: [
        { text: 'English', value: 'en' },
        { text: 'Español', value: 'es' },
        { text: 'Română', value: 'ro' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      isAdmin: 'auth/isAdmin',
      isSuperadmin: 'auth/isSuperadmin',
      locale: 'i18n/locale',
      currentUserGroup: 'auth/currentUserGroup',
      organization: 'organization/organization',
      logo: 'organization/logo'
    }),
    lang() {
      return this.locales.find(item => item.value === this.langParam)
    },
    routePath() {
      return this.$route.path
    },
    listItemGroups() {
      return this.createListItemGroups()
    },
    listItems() {
      return this.createListItems()
    },
    userItems() {
      return this.createUserItems()
    },
    loadedLogo() {
      const staticLogo = require('../../assets/images/guitar.jpg')
      return this.logo || staticLogo
    }
  },
  methods: {
    toggle() {
      this.drawer = !this.drawer
    },
    async logOut() {
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' }).catch(() => {})
    },
    createListItemGroups() {
      return [
        {
          title: this.$tc('user.contact', 2),
          // icon: 'mdi-store-outline',
          icon: 'mdi-briefcase-account-outline',
          link: '',
          button: '',
          items: [
            {
              title: this.$tc('user.supplier', 2),
              // link: '/user-groups/supplier',
              name: 'user-groups',
              category: 'supplier',
              icon: 'mdi-store-outline'
              // button: '/user-groups/supplier/new'
            },
            {
              title: this.$tc('user.forestManager', 2),
              // link: '/user-groups/forest_manager',
              name: 'user-groups',
              category: 'forest_manager',
              icon: 'mdi-pine-tree'
              // button: '/user-groups/supplier/new'
            },
            {
              title: this.$tc('user.customer', 2),
              // link: '/user-groups/customer',
              name: 'user-groups',
              category: 'customer',
              icon: 'mdi-briefcase-account-outline'
              // button: '/user-groups/customer/new'
            }
          ]
        }
      ]
    },
    createListItems() {
      return [
        {
          title: this.$t('dashboard.name'),
          // link: '/',
          name: 'dashboard-show',
          icon: 'mdi-monitor-dashboard'
          // button: ''
        },
        {
          title: this.$t('purchase.shortName'),
          // link: '/orders/purchase-order',
          name: 'purchase-order-index',
          icon: 'mdi-application-import'
          // button: '/user-groups/supplier/new'
        },
        {
          title: this.$t('sale.shortName'),
          link: '/orders/sale-order',
          name: 'sale-order-index',
          icon: 'mdi-application-export'
          // button: '/user-groups/customer/new'
        },
        {
          title: this.$tc('facility.name', 2),
          // link: '/inventory/facilities',
          name: 'facility-index',
          icon: 'mdi-warehouse'
          // button: '/inventory/item/new'
        },
        {
          title: this.$tc('lot.name', 2),
          // link: '/inventory/item-groups/lot',
          name: 'item-group-lot-index',
          icon: 'mdi-cube'
          // button: '/inventory/item-groups/lot/new'
        },
        {
          title: this.$tc('item.name', 2),
          link: '/inventory/items',
          name: 'inventory-item-index',
          icon: 'mdi-file-chart-outline'
          // button: '/inventory/item/new'
        },

        {
          title: this.$tc('workOrder.name', 2),
          icon: 'mdi-briefcase-outline',
          // link: '/task-groups',
          name: 'task-group-index',
          button: ''
        },
        {
          title: this.$t('common.billOfMaterial'),
          icon: 'mdi-music',
          // link: '/inventory/item-products',
          name: 'item-product-index',
          button: '/inventory/item-products/new'
        }
        // {
        // title: this.$tc('user.contact', 2),
        // link: '/users',
        // icon: 'mdi-briefcase-account-outline',
        // name: 'users',
        // button: '/users/supplier/new'
        // }
      ]
    },
    createUserItems() {
      return [
        {
          title: this.$tc('order.name', 2),
          link: '/orders/sale-order-customer',
          name: 'sale-order-customer-index',
          icon: 'mdi-briefcase-account-outline'
          // button: ''
        },
        {
          title: this.$t('inventory.name'),
          // link: '/inventory/items',
          name: 'inventory-item-index',
          icon: 'mdi-file-chart-outline'
          // button: '/inventory/item/new'
        },
        {
          title: this.$tc('product.name', 2),
          icon: 'mdi-music',
          // link: '/inventory/item-products',
          name: 'item-product-index',
          button: '/inventory/item-products/new'
        }
        // {
        //   title: this.$tc('user.contact', 2),
        //   // link: '/users',
        //   icon: 'mdi-briefcase-account-outline',
        //   name: 'users',
        //   button: '/users/supplier/new'
        // }
      ]
    },
    updateLocale(lang) {
      // this.$moment.locale(lang)
      if (lang === this.langParam) return
      this.langParam = lang
      this.$store.dispatch('i18n/changeLocale', lang)
      this.$vuetify.lang.current = lang
      this.$router.replace({
        name: this.$route.name,
        params: { ...this.$route.params, lang }
      })
    }
  }
}
</script>
