<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="-1"
    :color="notification.color"
    text
    top
    right
  >
    <v-icon left :color="notification.color">mdi-alert-box-outline</v-icon>

    <span v-for="item in computedNotification" :key="item.id">
      {{ item.messages }} - Code: {{ notification.status }}
    </span>

    <!-- <span class="font-weight-bold">{{ notification.title }}</span>
    <span v-if="notification.status[0] !== '2'">
      - Code: {{ notification.status }}</span
    > -->
  </v-snackbar>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      snackbar: true,
      timeout: null
    }
  },
  computed: {
    computedNotification() {
      return this.notification.constructor === Array
        ? this.notification
        : [this.notification]
    }
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.remove(this.notification)
      this.snackbar = false
    }, this.notification.time)
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    ...mapActions({ remove: 'notification/remove' })
  }
}
</script>
