var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"dark":"","app":"","permanent":"","expand-on-hover":"","width":"20%"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"value":false,"color":"green lighten-4","no-action":"","prepend-icon":"mdi-google-translate"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.translate')))])],1)]},proxy:true}])},_vm._l((_vm.locales),function(locale,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.updateLocale(locale.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(locale.text))])],1)}),1),_c('v-list-item',{attrs:{"to":{
          name: 'user-edit',
          params: {
            role: _vm.currentUser.attributes.roles[0],
            userId: _vm.currentUser.id
          }
        }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-cog-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.editProfile')))])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logOut}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('auth.logout')))])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[(_vm.isAdmin)?_c('v-list-item',{staticClass:"px-2",attrs:{"disabled":!_vm.isAdmin || !_vm.currentUserGroup,"to":{
        name: 'user-group-show',
        params: {
          category: 'supplier',
          groupId: _vm.currentUserGroup && _vm.currentUserGroup.id,
          lang: _vm.locale
        }
      }}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.loadedLogo,"alt":"Avatar"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.organization.data.attributes.name || '-'))]),_c('v-list-item-subtitle',{staticClass:"green--text"},[_vm._v("Hello, "+_vm._s(_vm.currentUser.attributes.info.name || _vm.currentUser.attributes.email))])],1)],1):_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.loadedLogo,"alt":"Avatar"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.organization.data.attributes.name || '-'))]),_c('v-list-item-subtitle',{staticClass:"green--text"},[_vm._v("Wood for Music")])],1)],1),_c('v-divider')],1),(_vm.isAdmin)?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.listItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":{ name: item.name, params: { lang: _vm.locale } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),_vm._l((_vm.listItemGroups),function(item){return _c('v-list-group',{key:item.title,attrs:{"value":false,"color":"green lighten-4","prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true)},_vm._l((item.items),function(subItem){return _c('v-list-item',{key:subItem.title,attrs:{"to":{
          name: subItem.name,
          params: { category: subItem.category, lang: _vm.locale }
        }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(subItem.title)}})],1)}),1)})],2):_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.userItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":{ name: item.name, params: { lang: _vm.locale } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }